<template>
  <v-card>
    <v-card-title>
      {{ $t('ResetPassword') }}
    </v-card-title>

    <v-card-text>
      <v-form
        ref="setPasswordForm"
        @submit.prevent="handleFormSubmit"
      >
        <v-text-field
          v-model="password"
          dense
          outlined
          :type="isPasswordVisible ? 'text' : 'password'"
          :label="$t('NewPassword')"
          :placeholder="$t('NewPassword')"
          :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
          hide-details="auto"
          class="mb-3"
          :rules="[validators.required, validators.passwordValidator]"
          validate-on-blur
          @click:append="isPasswordVisible = !isPasswordVisible"
        ></v-text-field>

        <v-text-field
          v-model="confirmPassword"
          dense
          outlined
          :type="isConfirmPasswordVisible ? 'text' : 'password'"
          :label="$t('ConfirmPassword')"
          :placeholder="$t('ConfirmPassword')"
          :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
          hide-details="auto"
          :rules="[
            validators.required, validators.confirmPasswordValidator(password, confirmPassword)
          ]"
          validate-on-blur
          @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
        ></v-text-field>

        <v-row class="mt-4">
          <v-col>
            <v-btn
              block
              color="primary"
              type="submit"
              :loading="loading"
            >
              {{ $t('Submit') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              outlined
              block
              @click="$router.back()"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { required, passwordValidator, confirmPasswordValidator } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import store from '@/store'
import AccountService from '@/services/AccountService'

export default {
  setup() {
    // Template Refs
    const setPasswordForm = ref(null)
    const { router } = useRouter()
    const userId = router.currentRoute.params.id
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const password = ref('')
    const confirmPassword = ref('')
    const loading = ref(false)

    const handleFormSubmit = async () => {
      const isFormValid = setPasswordForm.value.validate()

      if (!isFormValid) return
      loading.value = true
      try {
        await AccountService.changePasswordUser(userId, {
          password: password.value,
        })

        store.commit('snackbar/show', {
          message: 'Update Password Successfully',
          type: 'success',
        })

        router.push({ name: 'staff-user-detail' })
      } catch {
      } finally {
        loading.value = false
      }
    }

    return {
      loading,
      isPasswordVisible,
      isConfirmPasswordVisible,
      password,
      confirmPassword,
      handleFormSubmit,
      validators: {
        required,
        passwordValidator,
        confirmPasswordValidator,
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // Template Refs
      setPasswordForm,
    }
  },
}
</script>
